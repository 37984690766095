export class NotificationMessage{
    message!: string;
    messageType!: MessageType
}

export enum MessageType {
    Success = "success",
    Warning = "warning",
    Error = "danger"
  }

  export type FeatureFlags = {
    payroll?:any
    ivm?:any
    schedule?:any
    // paychexIntegration?: boolean
  }

  export type ClientFeatureFlags = {
    [key: string]: FeatureFlags;
  }

  export type DownloadData = {
    body: any[][],
    head: any[],
    foot?: any[]
  }
